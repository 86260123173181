// react
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// third-party
// import Moment from 'moment';
// import classNames from 'classnames';
// import Moment from 'moment';
import {
    Accordion,
    Avatar,
    Group,
    Text,
    createStyles,
} from '@mantine/core';
import ReactHTMLParser from 'react-html-parser';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getData } from '../../../services/fetch-service';
// import Pagination from '../../shared/Pagination';
import PostLoader from '../../blocks/PostLoader';

const useStyles = createStyles(() => ({
    accordionPanel: {
        // backgroundColor: '#1c7ed6',
        color: 'black',
        paddingLeft: '105px',
    },

    inlineText: {
        display: 'inline-block',
        marginRight: '5px', // Optional: adds space between names
    },
    labelText: {
        fontWeight: 'bold',
        marginBottom: '5px', // Space between label and content
    },
}));

function PersonelCardRrethArkep(props) {
    const { layout } = props;
    const params = useParams();
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [personeli, setPersoneli] = useState([]);
    const [loader, setLoader] = useState(false);

    const handleChange = (skip = 0) => {
        setLoader(true);
        getData(`Personel/GetPersonels?PageID=${params.id}&Gjuha=${locale}&skip=${skip}&take=9`).then((response) => {
            setPersoneli(response.data.result);
            setLoader(false);
        });
        setLoader(false);
    }

    useEffect(() => {
        handleChange();
    }, [url]);

    const path = process.env.REACT_APP_FILESPATH;
    const { classes } = useStyles();
    const personeliList = personeli.map((personeli, index) => {
        const image = `${path}${personeli.media?.mediaEmri}${personeli.media?.mediaEx}`;
        return (
            <Accordion.Item key={index} className={classes.item} value={`${personeli.personeliId}`}>
                <Accordion.Control>
                    <Group noWrap>
                        <Avatar src={image} radius="xl" size="xl" />
                        <div>
                            <Text className={classes.inlineText}>{personeli.personeliEmri}</Text>
                            <Text className={classes.inlineText}>{personeli.personeliMbiemri}</Text>
                            <Text>{personeli.personeliPozita}</Text>
                        </div>
                    </Group>
                    {/* {personeli.personeliPozita} */}
                </Accordion.Control>
                {(personeli.personeliDataLindjes !== null && (
                    <Accordion.Panel className={classes.accordionPanel}>
                        <FormattedMessage className={classes.labelText} id="Personel.card.birthday" defaultMessage="Example Text" />
                        {personeli.personeliDataLindjes}
                    </Accordion.Panel>
                ))}
                {personeli.presoneliVendiLindjes === '' ? ('')
                    : (
                        <Accordion.Panel className={classes.accordionPanel}>
                            <FormattedMessage className={classes.labelText} id="Personel.card.bornlocation" defaultMessage="Example Text" />
                            {personeli.presoneliVendiLindjes}
                        </Accordion.Panel>
                    )}
                {personeli.personeliEmail === '' ? ('')
                    : (
                        <Accordion.Panel className={classes.accordionPanel}>
                            <FormattedMessage className={classes.labelText} id="Personel.card.email" defaultMessage="Example Text" />
                            {personeli.personeliEmail}
                        </Accordion.Panel>
                    )}
                {personeli.personeliNrTelefonit === '' ? ('')
                    : (
                        <Accordion.Panel className={classes.accordionPanel}>
                            <FormattedMessage className={classes.labelText} id="Personel.card.phone" defaultMessage="Example Text" />
                            {personeli.personeliNrTelefonit}
                        </Accordion.Panel>
                    )}
                <Accordion.Panel className={classes.accordionPanel}>{ReactHTMLParser(personeli.personeliInformataShtes)}</Accordion.Panel>
            </Accordion.Item>
        );
    });

    return (
        <div>
            <div className="aboutus-bordi">
                <div className="row">
                    <div className="block personel-block">
                        <div className="posts-view">
                            <div className={`posts-view__list-arkep posts-list posts-list--layout--${layout}`}>
                                <div className="arkep-keshilli">
                                    {/* <h6><FormattedMessage id="Keshilli-Drejtues" defaultMessage="KËSHILLI DREJTUES" /></h6> */}
                                    <h1><FormattedMessage id="Bordi-Arkep" defaultMessage="Bordi ARKEP" /></h1>
                                </div>
                                <div className="personel-details-data-aboutus">
                                    {
                                        loader === true ? <PostLoader /> : ''
                                    }
                                    <Accordion variant="separated">
                                        {personeliList}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

PersonelCardRrethArkep.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['grid', 'grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};
PersonelCardRrethArkep.defaultProps = {
    layout: 'grid',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PersonelCardRrethArkep);
